import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import Alert from "@mui/material/Alert/Alert";
import { Snackbar } from "@mui/material";
import { TARGET_SAMPLE_RATE, useAlison } from "./useAlison";
import { CoughWidget, DotPulse } from "../components";

interface AlisonProps {
  showLearnMore?: boolean;
}

const AlisonDemo = ({ showLearnMore = true }: AlisonProps) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [sampleRateAlertOpen, setSampleRateAlertOpen] = useState(false);
  const { coughCount, sampleRate } = useAlison({
    canvas: canvasRef.current,
    canvasWidthPx: 800,
    canvasHeightPx: 300,
  });

  useEffect(() => {
    if (sampleRate !== undefined) {
      if (sampleRate < TARGET_SAMPLE_RATE) {
        setSampleRateAlertOpen(true);
      }
    }
  }, [sampleRate]);

  return (
    <AlisonStyle>
      <div className="container">
        <Snackbar
          open={sampleRateAlertOpen}
          onClose={() => setSampleRateAlertOpen(false)}
          autoHideDuration={4000}
        >
          <Alert
            severity="warning"
            onClose={() => setSampleRateAlertOpen(false)}
          >
            {`Invalid microphone sample rate! Expected at least ${(
              TARGET_SAMPLE_RATE / 1000
            ).toFixed(1)}KHz Found: ${((sampleRate || 0) / 1000).toFixed(
              1
            )}KHz. Cough Detection might be inaccurate.`}
          </Alert>
        </Snackbar>

        <div className="tracking">
          <div className="text">Tracking</div>
          <DotPulse color="white" />
        </div>
        <div className="main">
          <div className="waveform">
            <canvas ref={canvasRef} />
          </div>
          <CoughWidget count={coughCount} />
        </div>
      </div>
      {showLearnMore && (
        <span className="learnMore">
          Learn more about our app, <br />
          or &nbsp;
          <a className="expert" href="https://hyfe.ai">
            talk to a hyfe expert
          </a>
        </span>
      )}
    </AlisonStyle>
  );
};

const AlisonStyle = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;

  .container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .waveform {
    width: 800px;
    direction: rtl;
    overflow-x: hidden;
  }
  .tracking {
    display: flex;
    flex-direction: row;
    gap: 30px;
    justify-content: center;
    align-items: center;
    .text {
      //styleName: Headline 3;
      font-family: Poppins;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: center;
      color: #ffffff;
    }

    padding-bottom: 92px;

    @media only screen and (max-width: 600px) {
      padding-bottom: 40px;
    }
  }

  .main {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    flex-wrap: wrap;
    column-gap: 80px;
    row-gap: 20px;
  }

  .learnMore {
    //styleName: Body 1;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;

    color: #d7dbe0;

    padding-bottom: 5vh;
  }
  .expert {
    color: #3891a6;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    text-decoration: underline;
  }
`;

export default AlisonDemo;
