import { useCallback, useEffect, useRef, useState } from "react";

import {
  useAudioPipeline,
  usePeakDetectionWorker,
  useTFJSCoughDetectorWorker,
  ChunkDispenserCallback,
  PeakDetectorCallback,
  useWaveform,
  WaveformEventType,
  ClassifyCallback,
} from "@hyfe/web-ml-tools";

export const TARGET_SAMPLE_RATE = 44100;

const peakDetectionParams = {};
const otherParams = {
  chunkDurationMs: 800,
  chunkDispensePeriodMs: 500,
  showChunks: 0,
  showPeaks: 0,
  useEI: 0,
  useTFJS: 1,
  threshold: 0.8,
};
const playFromFile = false;

interface useAlisonProps {
  canvas: HTMLCanvasElement | null;
  canvasWidthPx: number;
  canvasHeightPx: number;
}

export const useAlison = ({
  canvas,
  canvasWidthPx,
  canvasHeightPx,
}: useAlisonProps) => {
  const [coughTs, setCoughTs] = useState<number[]>([]);

  const [SR, setSR] = useState<number | undefined>();

  const updateUniqueCoughs = (newCoughs: { start: number; end: number }[]) => {
    setCoughTs((cts) => {
      let lastCoughTs = cts.length > 0 ? cts[cts.length - 1] : 0;
      const newUniqueCoughTs = newCoughs
        .filter((c) => {
          if (c.start > lastCoughTs) {
            lastCoughTs = c.end;
            return true;
          }
          return false;
        })
        .map((c) => c.end);
      return [...cts, ...newUniqueCoughTs];
    });
  };

  const { updateEvents, updateFFTData, fftSize } = useWaveform({
    canvas: canvas,
    cfg: { sampleRate: SR, canvasWidthPx, canvasHeightPx },
  });

  ////////////////////////////////////////////////////////////////////////////////
  //                      ML PIPELINE CALLBACKS START                           //
  ////////////////////////////////////////////////////////////////////////////////

  const classificationCallback: ClassifyCallback = (data) => {
    if (data.result[1] > 0.5) {
      updateEvents([
        {
          eventType: "COUGH_2",
          startTs: data.ts,
          endTs: data.ts + 333,
        },
      ]);
      updateUniqueCoughs([{ start: data.ts, end: data.ts + 333 }]);
    }
  };

  const { classify: tfjsClassify, info: tfjsInfo, isInit } = useTFJSCoughDetectorWorker(
    classificationCallback
  );

  // Called by the peak detector when peaks have been found
  const peakDetectorCallback: PeakDetectorCallback = useCallback(
    (data) => {
      let peakEvents: WaveformEventType[] = [];
      if (data.peaks && data.peaks.length > 0) {
        if (otherParams.showPeaks) {
          peakEvents = data.peaks.map((p) => ({
            ...p,
            eventType: "PEAK",
          }));
          updateEvents(peakEvents);
        }
        data.peaks.forEach((peak) => {
          if (otherParams.useTFJS) tfjsClassify(peak.samples, peak.startTs);
        });
      }
    },
    [peakDetectionParams, otherParams, updateEvents, tfjsClassify]
  );

  const { detectPeaks,  } = usePeakDetectionWorker(
    peakDetectionParams,
    peakDetectorCallback
  );
  // Called periodically by the chunk dispenser
  const chunkDispenserCallback: ChunkDispenserCallback = useCallback(
    (data) => {
      if (otherParams.showChunks) {
        const frameEvent: WaveformEventType = {
          startTs: data.startTimestamp,
          endTs: data.endTimestamp,
          eventType: "FRAME",
        };
        updateEvents([frameEvent]);
      }
      detectPeaks(data.samples, data.startTimestamp);
    },
    [detectPeaks, otherParams, updateEvents]
  );

  const { sourceSampleRate } = useAudioPipeline({
    audioSourceId: "default",
    useMicrophone: true,
    analyzerParams: {
      fftSize,
    },
    chunkDispenserParams: {
      chunkDurationMs: otherParams.chunkDurationMs,
      dispensePeriodMs: otherParams.chunkDispensePeriodMs,
      outputSampleRate: 44100,
    },
    analyzerCallback: updateFFTData,
    chunkDispenserCallback,
    channelStrategy: "merge",
    gain: 1,
    useFile: false,
    file: null,
    connectToSpeakers: playFromFile,
  });
  ////////////////////////////////////////////////////////////////////////////////
  //                      ML PIPELINE CALLBACKS END                             //
  ////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (sourceSampleRate !== undefined && sourceSampleRate != SR) {
      setSR(sourceSampleRate);
    }
  }, [sourceSampleRate]);

  return { sampleRate: SR, coughCount: coughTs.length };
};
