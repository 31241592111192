import { useEffect, useState } from "react";
import "./App.css";
import styled from "styled-components";
import Alison from "./alison/alisonDemo";

import MicLarge from "./assets/Mic_disabled_big.svg";
import Shield from "./assets/shield.svg";

import PlayStore from "./assets/googlePlay.png";
import AppStore from "./assets/appStore.svg";

import { HyfeButton, SubTitle, Title } from "./components";
import { getCountry, isPhoneBrowser } from "./misc";

enum Status {
  init = 0,
  isDesktop = 1,
  isPhone = 2,
  started = 3,
  permissionGranted = 4,
  permissionDenied = 5,
  toAlison = 6,
}

const CT_APPLE_LINK = "https://apps.apple.com/app/id1637049898";
const CT_GOOGLE_LINK =
  "https://play.google.com/store/apps/details?id=com.coughtracker";
const CP_APPLE_LINK = "https://apps.apple.com/us/app/hyfe/id1505735530";
const CP_GOOGLE_LINK =
  "https://play.google.com/store/apps/details?id=com.hyfeapp";

function App() {
  const [status, setStatus] = useState<Status>(Status.init);
  const [isUs, setIsUS] = useState(false);

  const [showLearnMore, setShowLearnMore] = useState(false);

  useEffect(() => {
    setIsUS(getCountry() === "United States of America");

    if (isPhoneBrowser()) {
      setStatus(Status.isPhone);
    } else {
      setStatus(Status.isDesktop);
    }
  }, []);

  useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    setShowLearnMore(params.get("hideLearnMore") ? false : true);
  }, []);

  const triggerMicrophoneReq = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(function (stream) {
        setStatus(Status.permissionGranted);
      })
      .catch(function (err) {
        setStatus(Status.permissionDenied);
      });
  };

  const onStarted = () => {
    setStatus(Status.started);
    triggerMicrophoneReq();
  };

  return (
    <Container className="app">
      {status == Status.init}
      {status == Status.isPhone && (
        <Phone>
          <Title className="title">
            Alison works better in the browser or in the app
          </Title>
          <SubTitle className="subTitle">
            Download the app below <br />
            or try with your computer
          </SubTitle>
          <div className="links">
            <a href={isUs ? CT_APPLE_LINK : CP_APPLE_LINK}>
              <img src={AppStore} alt="AppStore link" className="apple" />
            </a>
            <a href={isUs ? CT_GOOGLE_LINK : CP_GOOGLE_LINK}>
              <img src={PlayStore} alt="Google play link" className="google" />
            </a>
          </div>
          <div className="spacer"></div>
        </Phone>
      )}
      {status == Status.isDesktop && (
        <ReadyToTrack>
          <Title className="title">Ready to track coughs?</Title>
          <SubTitle className="subTitle">
            Learn more about our technology <br />
            and experience cough detection in real time
          </SubTitle>
          <HyfeButton className="button" onClick={onStarted}>
            Start Tracking My Coughs
          </HyfeButton>
          <div className="spacer"></div>
        </ReadyToTrack>
      )}
      {status == Status.started && (
        <MicrophoneAccess>
          <div className="safe">
            <img src={Shield} alt="shield" style={{ userSelect: "none" }} />
            <span>All sound data remains on your device</span>
          </div>
          <div className="wrapper">
            <img className="mic" src={MicLarge} alt="mic disabled" />
            <Title className="title">Allow access to your microphone</Title>
            <SubTitle className="subTitle">
              In order to analyze sound data and detect coughs,
              <br />
              the system requires you to enable microphone access
            </SubTitle>
          </div>
        </MicrophoneAccess>
      )}
      {status == Status.permissionGranted && (
        <Alison showLearnMore={showLearnMore} />
      )}
    </Container>
  );
}

export default App;

const Container = styled.div`
  min-height: 500px;
  height: 100vh;
  background: radial-gradient(50% 50% at 50% 50%, #2a426f 0%, #213458 100%);
`;
const Phone = styled.div`
  height: 100%;
  margin: auto;
  max-width: 800px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  .title {
    padding-bottom: 32px;
  }

  .subTitle {
    padding-bottom: 72px;
  }

  .links {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* column-gap: 32px; */
    row-gap: 12px;

    a {
      padding: 0px 16px;
      img {
        height: 50px;
      }
    }
  }
  .spacer {
    height: 40%;
  }

  @media only screen and (max-width: 600px) {
    .title {
      padding-bottom: 32px;
    }

    .subTitle {
      padding-bottom: 56px;
    }

    .spacer {
      height: 30%;
    }

    .button {
      font-size: 14px !important;
    }
  }
`;
const ReadyToTrack = styled.div`
  height: 100%;
  margin: auto;
  max-width: 800px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  .title {
    padding-bottom: 32px;
  }

  .subTitle {
    padding-bottom: 72px;
  }

  .spacer {
    height: 50%;
  }

  @media only screen and (max-width: 600px) {
    .title {
      padding-bottom: 32px;
    }

    .subTitle {
      padding-bottom: 56px;
    }

    .spacer {
      height: 40%;
    }

    .button {
      font-size: 14px !important;
    }
  }
`;
const MicrophoneAccess = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  .safe {
    user-select: none;
    justify-self: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
    padding: 24px 20px 0px 20px;

    span {
      color: #bdc667;
    }
  }
  .wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }

  .mic {
    translate: 20%;
    padding-bottom: 5vh;
    width: 190px;
  }
  .title {
    padding-bottom: 32px;
  }

  .subTitle {
    height: calc(50vh - 32px);
  }

  @media only screen and (max-width: 600px) {
    .mic {
      padding-bottom: 62px;
      width: 115px;
    }
    .title {
      padding-bottom: 32px;
    }

    .subTitle {
      height: calc(50vh - 32px);
    }
  }
`;
