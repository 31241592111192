import { useEffect, useRef } from "react";
import styled from "styled-components";

interface HyfeButtonProps {
  outlined?: boolean;
}
export const HyfeButton = styled.button<HyfeButtonProps>`
  /* line-height: 50%; */

  font-size: 18px;

  box-shadow: 0px 2px 24px rgba(20, 31, 52, 0.3);
  border-radius: 50px;

  padding: 16px 32px;
  margin: 0px 20px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;

  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;

  color: ${({ outlined }) => (outlined ? "#3891A6" : "#fff")};

  background-color: ${({ outlined }) =>
    outlined ? "rgba(0,0,0,0)" : "#3891a6"};

  border-width: 2px;
  border-style: solid;
  border-color: #3891a6;
  user-select: none;

  &:hover {
    background-color: ${({ outlined }) =>
      outlined ? "rgba(0,0,0,0)" : "#4BADC5"};
    border-color: #4badc5;
    color: ${({ outlined }) => (outlined ? "#4badc5" : "#fff")};
  }

  @media only screen and (max-width: 600px) {
    font-size: 14px !important;
  }
`;

export const Title = styled.span`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;

  text-align: center;
  word-wrap: normal;

  color: #c0e2ea;
  user-select: none;

  @media only screen and (max-width: 600px) {
    font-size: 25px;
    line-height: 32px;
  }

  padding: 0px 20px;
`;
export const SubTitle = styled.span`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;

  text-align: center;

  color: #778397;
  user-select: none;
  padding: 0px 20px;

  @media only screen and (max-width: 600px) {
    font-size: 18px;
    line-height: 22px;
  }
`;

export const CoughWidget = ({ count }: { count: number }) => {
  const rippleRef = useRef<HTMLDivElement | null>(null);
  const widgetRef = useRef<HTMLDivElement | null>(null);

  const triggerRipple = () => {
    // clearTimeout(timerId);
    if (!rippleRef.current) return;
    if (!widgetRef.current) return;
    const widget = widgetRef.current;
    const ripple = rippleRef.current;

    ripple.classList.remove("active");
    ripple.classList.remove("start");
    setTimeout(() => {
      ripple.classList.add("start");
      setTimeout(() => {
        ripple.classList.add("active");
      });
    });

    let timerId = setTimeout(() => {
      ripple.classList.remove("active");
      ripple.classList.remove("start");
    }, 400);

    return () => clearTimeout(timerId);
  };

  useEffect(() => {
    return triggerRipple();
  }, [count]);

  return (
    <CoughWidgetStyled ref={widgetRef}>
      <div className="ripple " ref={rippleRef}></div>
      <div className="count">{count}</div>
      <div className="coughs">{count == 1 ? "cough" : "coughs"}</div>
    </CoughWidgetStyled>
  );
};
const CoughWidgetStyled = styled.div`
  background: linear-gradient(
    153.83deg,
    rgba(119, 131, 151, 0.2) -1.31%,
    rgba(234, 236, 239, 0.2) 91.9%
  );
  border-radius: 50%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .count {
    font-family: Poppins;
    font-size: 44px;
    font-weight: 700;
    line-height: 66px;
    letter-spacing: 0em;
    text-align: center;
    color: #c0e2ea;
  }

  .coughs {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    color: #d7dbe0;
  }

  width: 174px;
  height: 174px;

  @media only screen and (max-width: 600px) {
    width: 120px;
    height: 120px;
    .coughs {
      font-size: 14px;
      line-height: 19px;
    }

    .count {
      font-size: 32px;
      line-height: 48px;
    }
  }

  position: relative;
  .ripple {
    position: absolute;

    width: 70%;
    height: 70%;

    background-color: #aaa;
    border-radius: 50%;
    pointer-events: none;
    position: absolute;
    transform: scale(0);
  }
  .start {
    transform: scale(0.2);
    opacity: 0.5;
  }
  .active {
    transform: scale(2);
    transition: transform 500ms, opacity 500ms;
    opacity: 0;
  }
`;

interface DotPulseProps {
  color: string;
}
export const DotPulse = styled.div`
  /**
 * ==============================================
 * Dot Pulse
 * ==============================================
 */
  & {
    position: relative;
    left: -9999px;
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background-color: ${({ color }) => color};
    color: ${({ color }) => color};
    box-shadow: 9999px 0 0 -5px ${({ color }) => color};
    animation: dotPulse 1.5s infinite linear;
    animation-delay: 0.25s;
  }

  &::before,
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background-color: ${({ color }) => color};
    color: ${({ color }) => color};
  }

  &::before {
    box-shadow: 9984px 0 0 -5px ${({ color }) => color};
    animation: dotPulseBefore 1.5s infinite linear;
    animation-delay: 0s;
  }

  &::after {
    box-shadow: 10014px 0 0 -5px ${({ color }) => color};
    animation: dotPulseAfter 1.5s infinite linear;
    animation-delay: 0.5s;
  }

  @keyframes dotPulseBefore {
    0% {
      box-shadow: 9984px 0 0 -5px ${({ color }) => color};
    }
    30% {
      box-shadow: 9984px 0 0 2px ${({ color }) => color};
    }
    60%,
    100% {
      box-shadow: 9984px 0 0 -5px ${({ color }) => color};
    }
  }

  @keyframes dotPulse {
    0% {
      box-shadow: 9999px 0 0 -5px ${({ color }) => color};
    }
    30% {
      box-shadow: 9999px 0 0 2px ${({ color }) => color};
    }
    60%,
    100% {
      box-shadow: 9999px 0 0 -5px ${({ color }) => color};
    }
  }

  @keyframes dotPulseAfter {
    0% {
      box-shadow: 10014px 0 0 -5px ${({ color }) => color};
    }
    30% {
      box-shadow: 10014px 0 0 2px ${({ color }) => color};
    }
    60%,
    100% {
      box-shadow: 10014px 0 0 -5px ${({ color }) => color};
    }
  }
`;
